import { WireIsoService } from '@treasury/domain/wires';
import { ListeningElementMixin } from '@treasury/omega/components';
import '@treasury/omega/components/omega-additional-info';
import '@treasury/omega/components/omega-alert.js';
import '@treasury/omega/components/omega-datepicker.js';
import '@treasury/omega/components/omega-field.js';
import '@treasury/omega/components/omega-frequency/omega-frequency';
import '@treasury/omega/components/omega-input';
import '@treasury/omega/components/omega-select.js';
import '@treasury/omega/components/omega-table';
import { css, html, LitElement, nothing } from 'lit';
import { mix } from 'mixwith';

export class WireTable extends mix(LitElement).with(ListeningElementMixin) {
    static get properties() {
        return {
            records: Array,
            readOnly: { attribute: 'readonly', type: Boolean },
            accountConfiguration: Object,
            filters: Array,
            wireIsoService: Object,
            wireIsoLabels: Object,
        };
    }

    constructor() {
        super();
        this.wireIsoService = new WireIsoService();
        this.wireIsoLabels =  WireIsoService.legacyLabels;
    }

    async firstUpdated() {
        this.wireIsoLabels = await this.wireIsoService.getLabels();
        this.updateLabel();
    }

    updated(props) {
        if (props.has('records')) {
            this.updateLabel();
        }
    }

    static get is() {
        return 'wire-table';
    }

    async updateLabel() {
        if (await this.wireIsoService.getEntitlementWireISO20022()) {
            const table = this.shadowRoot.querySelector('omega-table');
            if (table && table.columns) {
                table.columns.find(column => column.field ==='beneficiaryName').label = `${this.wireIsoLabels.creditor} Name`;
                table.requestUpdate()
            }
        }
    }

    // eslint-disable-next-line class-methods-use-this
    print(record) {
        this.dispatchEvent(
            new CustomEvent('print', {
                composed: true,
                bubbles: true,
                detail: { value: record, config: this.accountConfiguration },
            })
        );
    }

    // eslint-disable-next-line class-methods-use-this
    expandInvalidRows(e) {
        const { records } = e.detail;
        for (const record of records) {
            record.isExpanded = !!record.values.data.errorSummary || record.isExpanded;
        }
        this.requestUpdate();
    }

    displayTooltip(record) {
        const errorSummary = record.getField('errorSummary');
        return !!errorSummary;
    }

    // eslint-disable-next-line class-methods-use-this
    renderErrorMessage(errorSummary) {
        const closeable = false;
        return html`<div>
            <omega-alert type="error" isVisible .closeable=${closeable}>
                ${errorSummary.summaryMessage}
                <ul>
                    ${errorSummary.details.map(
                        d => html`<li>
                            ${d.message}
                            <ul>
                                ${d.messageList.map(
                                    message => html`<li>${message.value}</li>`
                                )}</li>
                            </ul>
                        </li>`
                    )}
                </ul>
            </omega-alert>
        </div>`;
    }

    // eslint-disable-next-line
    renderWireDetail(record, close) {
        // eslint-disable-next-line no-param-reassign
        return html`
            <style>
                .content {
                    color: var(--omega-field-color);
                    font-weight: var(--omega-field-font-weight);
                }
                .columns {
                    display: flex;
                    padding: 16px;
                    line-height: 1.5;
                }
                .columns > div {
                    flex: 1 0 0px;
                    padding: 0 16px;
                    position: relative;
                }
                .columns > div:after {
                    content: '';
                    position: absolute;
                    top: 12px;
                    bottom: 12px;
                    right: 0px;
                    border-right: 1px solid var(--omega-divider-color);
                }
                .columns > div:last-child:after {
                    display: none;
                }
                .row {
                    display: flex;
                    padding: 8px 0;
                    align-items: center;
                }
                .row.no-align {
                    align-items: unset;
                }
                .row.no-pad {
                    padding: unset;
                }
                .row > * {
                    flex: 1 0 0px;
                }
                .row > label,
                .row > .label {
                    max-width: 228px;
                    color: var(--omega-label-color);
                    font-size: var(--omega-label);
                    font-weight: var(--omega-label-font-weight);
                }
                omega-field {
                    padding: 0;
                    width: 100%;
                }
                b {
                    font-weight: 600;
                }
            </style>
            <div class="omega-table-detail-header">
                <div>
                    <span><b>Wire Detail</b></span>
                </div>
                <div>
                    <omega-button
                        type="icon"
                        icon="print"
                        @click=${() => this.print(record)}
                        hideLabel="always"
                    ></omega-button>
                    <omega-button
                        type="icon"
                        icon="close"
                        @click=${close}
                        hideLabel="always"
                    ></omega-button>
                </div>
            </div>

            ${record.values.data.errorSummary
                ? this.renderErrorMessage(record.values.data.errorSummary)
                : null}
            <div class="columns">
                <!-- col 1 -->
                <div class="content">
                    <div class="row no-align">
                        <div class="label">${this.wireIsoLabels.creditor}</div>
                        <div>
                            <b>${record.print('beneficiaryName')}</b> <br />
                            Account Number: ${record.print('accountNumber')}<br />
                            Bank Id:${record.print('bankId')} <br />
                            Bank Name: ${record.print('bankName')} <br />
                            ${record.values.bank.city} ${record.values.bank.state}
                        </div>
                    </div>
                    ${record.getField('intermediaryBanks').length > 0
                        ? html`<div class="row no-align">
                              <div class="label">Intermediary</div>
                              ${record.getField('intermediaryBanks').map(
                                  bank => html`<div>
                                      Bank Id:${bank.bankId}<br />
                                      Bank Name: ${bank.name} <br />
                                      ${bank.city} ${bank.state}
                                  </div>`
                              )}
                          </div>`
                        : nothing}
                    <div class="row">
                        <div class="label">Wire Company Name</div>
                        <div>
                            <b>${record.print('wireCompanyName')}</b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="label">Debit Account</div>
                        <div>
                            ${this.accountConfiguration?.accountNicknames
                                ? record.print('debitAccount')
                                : record.print('debitAccountNumber')}
                        </div>
                    </div>
                </div>

                <!-- col 2 -->
                <div>
                    <div class="row">
                        <omega-field
                            field="wireAmount"
                            .readOnly=${this.readOnly}
                            .record=${record}
                            required
                        ></omega-field>
                    </div>
                    <div class="row">
                        <omega-field
                            field="frequency"
                            .readOnly=${this.readOnly}
                            .record=${record}
                            required
                        ></omega-field>
                    </div>
                </div>

                <!-- col 3 -->
                <div>
                    <div class="row">
                        <omega-field
                            field="purpose"
                            .readOnly=${this.readOnly}
                            .record=${record}
                            required
                        ></omega-field>
                    </div>
                    <div class="row">
                        <div>
                            <omega-field
                                field="additionalInformation"
                                .readOnly=${this.readOnly}
                                .record=${record}
                                no-align
                            ></omega-field>
                        </div>
                    </div>
                    <div class="row">
                        <omega-field
                            field="referenceBeneficiary"
                            .readOnly=${this.readOnly}
                            .record=${record}
                        ></omega-field>
                    </div>
                </div>
            </div>
        `;
    }

    render() {
        if (!this.records) return html`<div>No Records found</div>`;
        return html`
            <omega-table
                .detailRowTemplateFunction=${(record, close) =>
                    this.renderWireDetail(record, close)}
                .recordset=${this.records}
                @load=${this.expandInvalidRows}
            >
                ${!this.readOnly
                    ? html`<omega-table-checkbox-column
                              field="selected"
                          ></omega-table-checkbox-column>
                          <omega-table-read-only-column
                              field="error"
                          ></omega-table-read-only-column>`
                    : null}
                <omega-table-read-only-column
                    field="beneficiaryName"
                    label="Beneficiary Name"
                ></omega-table-read-only-column>
                ${this.accountConfiguration?.accountNicknames
                    ? html`<omega-table-read-only-column
                          field="debitAccount"
                          label="Debit Account"
                      ></omega-table-read-only-column>`
                    : html`<omega-table-read-only-column
                          field="debitAccountNumber"
                          label="Debit Account"
                      ></omega-table-read-only-column>`}

                <omega-table-read-only-column
                    field="wireCompanyName"
                    label="Wire Company Name"
                ></omega-table-read-only-column>
                <omega-table-read-only-column
                    field="effectiveDate"
                    label="Effective Date"
                ></omega-table-read-only-column>
                <omega-table-read-only-column
                    field="wireAmount"
                    label="Wire Amount"
                ></omega-table-read-only-column>
                <omega-table-detail-column display-toggle-all></omega-table-detail-column>
            </omega-table>
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    width: 100%;
                    --omega-label-font-weight: var(--omega-medium);
                    --omega-field-color: var(--omega-text-secondary);
                    --omega-field-font-weight: var(--omega-text-medium);
                }
            `,
        ];
    }
}

window.customElements.define(WireTable.is, WireTable);
export default WireTable;
