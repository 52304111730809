/* eslint-disable import/no-extraneous-dependencies, @treasury/filename-match-export */
import { LitElement, css, html, nothing } from 'lit';
import '../components/omega-button.js';
import '../components/omega-drag-and-drop.js';
import '../components/omega-radio-group.js';
import '../components/omega-select.js';
import '../components/omega-tabs.js';
import '../components/omega-tooltip.js';
import { fontAwesome } from '../css/icons/font-awesome.js';

const glossary = [
    {
        title: 'Payment Information',
        label: 'Payment Info',
        id: 'payInfo',
        terms: [
            { name: 'Wire Company Name', text: 'Originators information' },
            { name: 'Debit Account', text: 'Account originating the wire' },
            { name: 'Beneficiary', text: 'Recipient of funds' },
            { name: 'Frequency', text: 'One-time or recurring' },
            { name: 'Additional Information', text: 'Optional note' },
            { name: 'Effective Date', text: 'Date of payment' },
            { name: 'Wire Amount', text: 'Amount' },
            { name: 'Reference Beneficiary', text: 'Additional information on the beneficiary' },
            { name: 'Purpose', text: 'Reason for payment' },
        ],
    },
    {
        title: 'Beneficiary Information',
        label: 'Beneficiary Info',
        id: 'beneficiaryInfo',
        terms: [
            { name: 'Bank ID', text: 'Beneficiary bank routing number' },
            { name: 'Bank Name', text: 'Beneficiary bank name' },
            { name: 'Bank City', text: 'Beneficiary bank city' },
            { name: 'Bank State', text: 'Beneficiary bank state' },
            { name: 'Account Number', text: 'Account number of the recipient' },
            { name: 'Notes', text: 'Optional information' },
            { name: 'Name', text: 'Recipient name' },
            { name: 'Street Address', text: 'Recipient address' },
            { name: 'City', text: 'Recipient city' },
            { name: 'State', text: 'Recipient state' },
            { name: 'Zip Code', text: 'Recipient zip code' },
        ],
    },
    {
        title: 'Domestic Intermediary',
        label: 'Domestic Intermediary Info',
        id: 'domesticIntermediaryInfo',
        terms: [
            { name: 'Bank ID', text: 'Intermediary bank routing number' },
            { name: 'Bank Name', text: 'Intermediary bank name' },
            { name: 'Bank City', text: 'Intermediary bank city' },
            { name: 'Bank State', text: 'Intermediary bank state' },
        ],
    },
];

class OmegaFileFormatDesigner extends LitElement {
    static get properties() {
        return {
            format: {
                attribute: false,
                type: Object,
            },
            glossary: {
                type: Array,
            },
            /**
             * TODO: the entitlement will get removed from this view when we move from the mvp to the 'preview' dialog
             * and the entitlement will prevent a user from getting to this view entirely.
             */
            entitlement: {
                type: Boolean,
            },
            loading: {
                type: Boolean,
            },
            validators: Object,
        };
    }

    constructor() {
        super();
        this.isValid = true;
        this.glossary = glossary;
        this.itemAmountFormats = [
            { label: 'Decimal Included', value: 'DecimalIncluded' },
            { label: 'Whole Dollar (798 = 798.00)', value: 'WholeDollar' },
            { label: 'Implied Decimal (798 = 7.98)', value: 'ImpliedDecimal' },
        ];
        this.activeGlossaryTab = 0;
        this.validators = {
            name: true,
            excludeHeaderRows: true,
            excludeFooterRows: true,
        };
        this.entitlement = true;
    }

    firstUpdated() {
        this.validators = { ...this.validators, name: this.format.name?.length > 0 };
    }

    // review updated usage
    updated() {
        this.validateFormat();
    }

    setActiveTab(e) {
        this.format.type = e.detail.activeTab;
        this.format = { ...this.format };
    }

    validateInput(input, property, validator) {
        this.hasKeyed = true;
        this.format[property] = input.value;
        if (validator(input.value)) {
            this.validators = { ...this.validators, [property]: true };
            input.classList.remove('omega-file-format-input-invalid');
        } else {
            this.validators = { ...this.validators, [property]: false };
            input.classList.add('omega-file-format-input-invalid');
        }
    }

    validateFormat() {
        return (
            !this.format.tiles.some(tile => tile.active === 'true') ||
            Object.values(this.validators).some(value => !value)
        );
    }

    toggleGlossary() {
        this.shadowRoot.querySelector('.omega-modal-background').classList.toggle('show');
        this.shadowRoot.querySelector('#omega-file-format-glossary').classList.toggle('show');
    }

    switchGlossaryTab(event) {
        this.activeGlossaryTab = event.detail.activeTab;
        this.shadowRoot
            .querySelectorAll('.omega-tab-content')
            .forEach(content => content.classList.remove('show-tab-content'));

        this.shadowRoot
            .querySelector(`#${this.activeGlossaryTab}-content`)
            .classList.add('show-tab-content');
    }

    toggleLegend() {
        this.shadowRoot.querySelector('.omega-file-format-legend-detail').classList.toggle('show');
    }

    _updateFormat(e) {
        this.format = { ...this.format, tiles: e.detail.tiles };
    }

    _updateItemAmountFormat(e) {
        this.format.itemAmountFormat = e.target.value;
    }

    _onSave() {
        const saveEvent = new CustomEvent('save', {
            bubbles: true,
            composed: true,
            detail: { format: this.format },
        });
        this.dispatchEvent(saveEvent);
    }

    _onCancel() {
        const cancelEvent = new CustomEvent('cancel', { bubbles: true, composed: true });
        this.dispatchEvent(cancelEvent);
    }

    _isSelectedDateFormat(value) {
        return this.format.effectiveDateFormat === value;
    }

    render() {
        return html` <div class="omega-file-format-container">
                <section class="omega-file-format-title-section">
                    <header>
                        <h2 class="omega-file-format-title">${this.format.title}</h2>
                    </header>
                    <nav class="omega-file-format-navigation" role="navigation">
                        <label for="omega-tabs">Upload Format</label>
                        <omega-tabs
                            .tabs=${[
                                { label: 'Delimited', id: 'Delimited' },
                                { label: 'Fixed Position', id: 'FixedPosition' },
                            ]}
                            label="Upload Format"
                            activeTab=${this.format.type}
                            @switchTab=${this.setActiveTab}
                        ></omega-tabs>
                    </nav>
                </section>
                <div role="tabpanel">
                    <section class="omega-file-format-name-section">
                        <div class="omega-file-format-input-wrapper omega-form-field">
                            <label for="omega-file-format-name">Format Name &#42;</label>
                            <input
                                type="text"
                                name="omega-file-format-name"
                                id="omega-file-format-name"
                                class="omega-file-format-input"
                                .value="${this.format.name}"
                                @keyup=${e =>
                                    this.validateInput(
                                        e.target,
                                        'name',
                                        value => /^[\s\w-]+$/.test(value) && value.length <= 100
                                    )}
                            />
                            ${!this.validators.name && this.hasKeyed
                                ? html`<small class="omega-input-invalid-text"
                                      ><i class="fa fa-exclamation-circle"></i>&nbsp;${this.format
                                          .name
                                          ? html`Format Name must be less than 100 alphanumeric
                                            characters.`
                                          : html`Format name is required.`}</small
                                  >`
                                : nothing}
                        </div>
                        <span
                            >Text qualifier is identified by a single quote (') and a field
                            delimiter is identified by a comma (,)<br />If this format will be used
                            for files sent via FTP, the name should not contain any spaces or
                            underscores.</span
                        >
                    </section>
                    <section class="omega-file-format-options-section">
                        <header class="omega-file-format-section-header">
                            <p>
                                ${this.format.type === 'Delimited'
                                    ? this.format.type
                                    : 'Fixed Position'}
                            </p>
                            <omega-tooltip
                                icon="info-circle"
                                message="${this.format.type === 'Delimited'
                                    ? 'To add or edit a Delimited file upload format, select the order of the columns in your file.'
                                    : 'To add or edit a Fixed Position file upload format, select the position of the columns in your file.'}"
                            ></omega-tooltip>
                        </header>
                        <div class="omega-file-format-options-row">
                            <div class="exclude-inputs">
                                <div class="omega-file-format-input-wrapper omega-form-field">
                                    <label for="omega-file-format-exclude-headers"
                                        >Exclude Header Rows</label
                                    >
                                    <div class="input-container">
                                        <input
                                            type="number"
                                            min="0"
                                            max="99"
                                            name="omega-file-format-exclude-headers"
                                            id="omega-file-format-exclude-headers"
                                            class="omega-file-format-input"
                                            .value=${this.format.excludeHeaderRows}
                                            @change=${e =>
                                                this.validateInput(
                                                    e.target,
                                                    'excludeHeaderRows',
                                                    value => value < 100
                                                )}
                                        />

                                        <omega-tooltip
                                            icon="info-circle"
                                            message="If your wire file contains a header record or records, please enter the number of rows to be excluded"
                                        ></omega-tooltip>
                                        ${!this.validators.excludeHeaderRows
                                            ? html`<small class="omega-input-invalid-text"
                                                  ><i class="fa fa-exclamation-circle"></i
                                                  >&nbsp;Header Rows must be less than 100.</small
                                              >`
                                            : nothing}
                                    </div>
                                </div>
                                <div class="omega-file-format-input-wrapper omega-form-field">
                                    <label for="omega-file-format-exclude-footers"
                                        >Exclude Footer Rows</label
                                    >
                                    <div class="input-container">
                                        <input
                                            type="number"
                                            min="0"
                                            max="99"
                                            name="omega-file-format-exclude-footers"
                                            id="omega-file-format-exclude-footers"
                                            class="omega-file-format-input"
                                            .value=${this.format.excludeFooterRows}
                                            @change=${e =>
                                                this.validateInput(
                                                    e.target,
                                                    'excludeFooterRows',
                                                    value => value < 100
                                                )}
                                        />
                                        <omega-tooltip
                                            icon="info-circle"
                                            message="If your wire file contains a footer record or records, please enter the number of rows to be excluded"
                                        ></omega-tooltip>
                                        ${!this.validators.excludeFooterRows
                                            ? html`<small class="omega-input-invalid-text"
                                                  ><i class="fa fa-exclamation-circle"></i
                                                  >&nbsp;Footer Rows must be less than 100.</small
                                              >`
                                            : nothing}
                                    </div>
                                </div>
                            </div>
                            <omega-radio-group
                                id="item-amount-selection"
                                label="Item Amount"
                                orientation="column"
                                value=${this.format.itemAmountFormat}
                                name="item amount"
                                .radios=${this.itemAmountFormats}
                                @change=${this._updateItemAmountFormat}
                            ></omega-radio-group>
                            <div
                                class="omega-file-format-input-wrapper date-wrapper omega-form-field"
                            >
                                <label for="omega-file-format-effective-date-format"
                                    >Effective Date Format</label
                                >
                                <select
                                    aria-label="Effective Date Format"
                                    name="omega-file-format-effective-date-format"
                                    class="omega-file-format-select omega-file-format-input"
                                    @change=${e => {
                                        this.format.effectiveDateFormat = e.target.value;
                                    }}
                                >
                                    <option ?selected=${this._isSelectedDateFormat('mm/dd/yyyy')}>
                                        mm/dd/yyyy
                                    </option>
                                    <option ?selected=${this._isSelectedDateFormat('mm/dd/yy')}>
                                        mm/dd/yy
                                    </option>
                                    <option ?selected=${this._isSelectedDateFormat('mm-dd-yyyy')}>
                                        mm-dd-yyyy
                                    </option>
                                    <option ?selected=${this._isSelectedDateFormat('mm-dd-yy')}>
                                        mm-dd-yy
                                    </option>

                                    <option ?selected=${this._isSelectedDateFormat('dddyy')}>
                                        dddyy
                                    </option>
                                    <option ?selected=${this._isSelectedDateFormat('dddyyyy')}>
                                        dddyyyy
                                    </option>
                                    <option ?selected=${this._isSelectedDateFormat('ddmmyy')}>
                                        ddmmyy
                                    </option>
                                    <option ?selected=${this._isSelectedDateFormat('ddyymm')}>
                                        ddyymm
                                    </option>
                                    <option ?selected=${this._isSelectedDateFormat('ddmmyyyy')}>
                                        ddmmyyyy
                                    </option>
                                    <option ?selected=${this._isSelectedDateFormat('mmddyy')}>
                                        mmddyy
                                    </option>
                                    <option ?selected=${this._isSelectedDateFormat('mmyydd')}>
                                        mmyydd
                                    </option>
                                    <option ?selected=${this._isSelectedDateFormat('yyddd')}>
                                        yyddd
                                    </option>
                                    <option ?selected=${this._isSelectedDateFormat('yyddmm')}>
                                        yyddmm
                                    </option>
                                    <option ?selected=${this._isSelectedDateFormat('yymmdd')}>
                                        yymmdd
                                    </option>
                                    <option ?selected=${this._isSelectedDateFormat('yyyyddd')}>
                                        yyyyddd
                                    </option>
                                    <option ?selected=${this._isSelectedDateFormat('yyyyddmm')}>
                                        yyyyddmm
                                    </option>
                                    <option ?selected=${this._isSelectedDateFormat('yyyymmdd')}>
                                        yyyymmdd
                                    </option>
                                </select>
                            </div>
                        </div>
                    </section>
                    <section class="omega-file-format-column-order-section">
                        <div class="omega-file-format-column-order-section-header">
                            <span class="omega-file-format-column-order-section-title"
                                >${this.format.type === `Delimited`
                                    ? `Column Order`
                                    : `Fixed
                                      Positioning`}</span
                            >
                            <div class="omega-file-format-column-glossary">
                                <button
                                    id="omega-file-format-designer-glossary-button"
                                    @click=${this.toggleGlossary}
                                >
                                    Glossary
                                </button>
                                <span id="glossary-divider"></span>
                                <div class="omega-file-format-legend-detail">
                                    <div class="omega-file-format-legend-detail-header">
                                        <i
                                            class="fa fa-times"
                                            role="button"
                                            aria-label="Close"
                                            @click=${this.toggleLegend}
                                        ></i>
                                    </div>
                                    ${this.glossary.map(function(glossaryItem) {
                                        const swatchClasses = {
                                            'PaymentInformation': 'payment-information',
                                            'BeneficiaryInformation': 'beneficiary-information',
                                            'DomesticIntermediaryInformation': 'domestic-intermediary',
                                        }

                                        return html`<span
                                            class="${swatchClasses[glossaryItem.id]}-swatch swatch"
                                        ></span
                                        ><span> ${glossaryItem.title}</span><br />`;
                                    })}
                                </div>
                                <div
                                    class="omega-file-format-legend"
                                    role="button"
                                    aria-label="Toggle Legend"
                                    @click=${this.toggleLegend}
                                >
                                    <div class="payment-information-swatch swatch"></div>
                                    <div class="beneficiary-information-swatch swatch"></div>
                                    <div class="domestic-intermediary-swatch swatch"></div>
                                </div>
                            </div>
                        </div>
                        <omega-drag-and-drop
                            .containers=${this.format.containers}
                            .tiles=${this.format.tiles}
                            .type=${this.format.type}
                            @tilesUpdated=${this._updateFormat}
                        ></omega-drag-and-drop>
                    </section>
                </div>
                <footer class="omega-file-format-action-bar omega-action-bar">
                    <omega-button
                        id="omega-file-format-save-button"
                        type="primary"
                        ?disabled="${this.validateFormat() || !this.entitlement}"
                        ?loading=${this.loading}
                        @click=${this._onSave}
                        >Save</omega-button
                    >
                    <omega-button id="omega-file-format-cancel-button" @click=${this._onCancel}
                        >Cancel</omega-button
                    >
                </footer>
            </div>
            <div class="omega-modal-background"></div>
            <div id="omega-file-format-glossary" class="omega-modal" tabindex="0">
                <div class="omega-modal-header">
                    <span class="omega-modal-header-title">Wire Template Glossary of Terms</span>
                    <omega-button
                        type="icon"
                        icon="times"
                        hideLabel="always"
                        @click=${this.toggleGlossary}
                        >Close</omega-button
                    >
                </div>
                <omega-tabs
                    .tabs=${this.glossary}
                    label="Glossary Term Groups"
                    @switchTab=${this.switchGlossaryTab}
                ></omega-tabs>
                ${this.glossary.map(
                    section => html`
                        <div id=${`${section.id}-content`} class="omega-tab-content">
                            <ul class="omega-file-format-glossary-list">
                                ${section.terms.map(
                                    term => html`
                                        <li>
                                            <span class="glossary-term-name">${term.name}</span> -
                                            ${term.text}
                                        </li>
                                    `
                                )}
                            </ul>
                        </div>
                    `
                )}
            </div>`;
    }

    static get styles() {
        return [
            fontAwesome,
            css`
                :host {
                    display: block;
                    padding: 16px;
                }
                label {
                    font-weight: 500;
                    font-size: 14px;
                    color: #545454;
                }

                .omega-file-format-container {
                    background: #e6e6e6;
                    padding: 1px 0 1px 0;
                    border-radius: 4px;
                    margin-bottom: 50px;
                }

                .omega-file-format-input-row {
                    margin: 15px 5px;
                    flex-grow: 1;
                }

                .omega-file-format-input {
                    width: 80% !important;
                    height: 32px !important;
                    padding-left: 10px;
                    font-size: 12px;
                    margin-bottom: 3px;
                    border: 1px solid rgb(199, 199, 199);
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    -ms-appearance: none;
                    -o-appearance: none;
                    appearance: none;
                    border-radius: 3px;
                    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
                    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
                }

                .omega-file-format-input:focus {
                    outline: none;
                    border: 1px solid var(--omega-primary-lighten-100);
                    box-shadow: 0 2px 4px 0 rgba(102, 175, 233, 0.6);
                }

                .omega-file-format-input-invalid {
                    border: 1px solid var(--omega-error) !important;
                    background-color: rgba(204, 16, 16, 0.03) !important;
                    box-shadow: 0 0 0 1px rgb(193, 15, 0, 0.2) !important;
                    transition: all 200ms cubic-bezier(0, 0, 0.29, 0.75) !important;
                }

                .omega-input-invalid-text {
                    color: var(--omega-error);
                    font-size: 11px;
                }

                .omega-file-format-input-wrapper {
                    position: relative;
                    display: flex;
                    flex-grow: 1;
                    max-width: 435px;
                    flex-flow: row wrap;
                    height: 60px;
                    margin: 15px 5px;
                    align-items: center;
                }

                .omega-file-format-name-section .omega-file-format-input-wrapper {
                    margin-top: 0;
                }

                .date-wrapper {
                    max-width: 180px;
                }

                .omega-file-format-options-section .omega-file-format-input-wrapper {
                    display: block;
                    flex-grow: 0;
                    flex-shrink: 1;
                }

                .omega-file-format-input-wrapper .input-container {
                    display: flex;
                    align-items: center;
                    width: 170px;
                }

                .omega-file-format-container section {
                    background: white;
                    border: 1px solid #e0e0e0;
                    border-radius: 4px;
                    margin: 4px 10px;
                    padding: 10px;
                }

                section.omega-file-format-title-section {
                    min-height: 65px;
                    margin: 0;
                    padding: 10px 24px 6px;
                    border-radius: 0;
                    border: none;
                }

                .omega-file-format-title-section h2 {
                    font-size: 16px;
                    margin: 0;
                    font-weight: 500;
                }

                .omega-file-format-section-header p {
                    display: inline-block;
                    font-size: 14px;
                    text-transform: capitalize;
                    font-weight: 500;
                    margin: 8px 5px;
                }

                .omega-file-format-navigation {
                    position: relative;
                    bottom: -18px;
                    display: flex;
                    justify-items: flex-end;
                }

                .omega-file-format-navigation label {
                    line-height: 30px;
                    padding-right: 5px;
                    font-weight: 500;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 125px;
                    font-size: 13px;
                    color: #545454;
                }

                .omega-tab-content {
                    display: none;
                }

                .omega-tab-content.show-tab-content {
                    display: block;
                    border-top: 1px solid #d4d2d2;
                }

                .omega-file-format-name-section {
                    display: flex;
                    flex-wrap: wrap;
                    min-height: 100px;
                    align-items: center;
                }

                .omega-file-format-name-section span {
                    font-size: 13px;
                }

                #omega-file-format-name {
                    min-width: 300px;
                }

                .omega-file-format-options-row {
                    display: flex;
                    justify-content: space-between;
                }

                .omega-file-format-options-row label {
                    font-weight: 400;
                    color: #444444;
                }

                .omega-file-format-select {
                    padding: 6px 10px;
                    margin-bottom: 3px;
                    border: 1px solid rgb(199, 199, 199);
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    -ms-appearance: none;
                    -o-appearance: none;
                    appearance: none;
                    border-radius: 3px;
                    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
                    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
                    background: transparent;
                    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
                    background-repeat: no-repeat;
                    background-position-x: 100%;
                    background-position-y: 4px;
                }

                .omega-file-format-column-order-section-header {
                    display: flex;
                    justify-content: space-between;
                }
                .omega-file-format-column-order-section-title {
                    font-weight: 500;
                    font-size: 14px;
                    color: #545454;
                }

                .omega-file-format-column-glossary {
                    position: relative;
                    display: flex;
                    align-items: center;
                }

                .omega-file-format-column-glossary button {
                    cursor: pointer;
                    font-family: inherit;
                    font-size: 13px;
                    color: var(--omega-primary);
                    border: none;
                    background: none;
                    padding: 0;
                }

                #glossary-divider {
                    border-right: 1px solid #e0e0e0;
                    margin: 0 8px;
                    height: 20px;
                }

                /* LEGEND */
                .omega-file-format-legend {
                    position: relative;
                    display: inline-block;
                    cursor: pointer;
                    height: 25px;
                }

                .omega-file-format-legend:focus {
                    outline: none;
                }

                .omega-file-format-legend-detail {
                    position: absolute;
                    bottom: -15px;
                    right: -115px;
                    width: 200px;
                    background: #fff;
                    border: 1px solid rgba(0, 101, 207, 0.5);
                    box-shadow: 0 0 10px 0 rgba(57, 57, 57, 0.5);
                    border-radius: 4px;
                    padding: 5px 11px 15px 11px;
                    opacity: 0;
                    z-index: -10;
                    transform: translate(-50%, -50%) rotateX(-180deg);
                    transition: all 0.25s cubic-bezier(0.6, -0.28, 0.735, 0.045);
                }

                .omega-file-format-legend-detail.show {
                    opacity: 1;
                    z-index: 100;
                    transform: translate(-50%, -50%) rotateX(0deg);
                    transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
                }

                .omega-file-format-legend-detail-header {
                    display: flex;
                    justify-content: flex-end;
                    color: rgb(168, 157, 157);
                    font-size: 14px;
                    margin-bottom: 3px;
                }

                .swatch {
                    position: relative;
                    display: inline-block;
                    height: 7px;
                    width: 7px;
                    border-radius: 50%;
                }

                .payment-information-swatch {
                    background-color: #19c466;
                }

                .beneficiary-information-swatch {
                    background-color: #00bde9;
                }

                .domestic-intermediary-swatch {
                    background-color: #a5a5a5;
                }

                .omega-file-format-legend .domestic-intermediary-swatch {
                    top: 9px;
                    right: 15.5px;
                }

                .omega-action-bar {
                    position: fixed;
                    bottom: 0;
                    left: 16px;
                    right: 16px;
                    margin-bottom: 56px; /* only to adjust for channel */
                    height: 50px;
                    background: white;
                    border-top: 1px solid #e0e0e0;
                }

                .omega-action-bar::after {
                    /* dirty dirty hacks */
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 50px;
                    height: 30px;
                    background: #eeeeee;
                }

                /* GLOSSARY (MODAL) */
                .omega-modal {
                    position: fixed;
                    box-shadow: 0px 0px 8px 0px #333;
                    text-align: left;
                    background: #fff;
                    padding: 5px 20px;
                }

                .omega-modal-background {
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background: rgba(9, 17, 9, 0.75);
                    opacity: 0;
                    z-index: -1;
                    height: 100%;
                    width: 100%;
                    transition: all 0.5s cubic-bezier(0.6, -0.28, 0.735, 0.045);
                }

                .omega-modal-background.show {
                    position: fixed;
                    display: block;
                    opacity: 1;
                    z-index: 10;
                    transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
                }

                .omega-modal-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                .omega-modal-header-title {
                    width: 100%;
                    text-align: center;
                    font-size: 16px;
                    color: #5a5b5a;
                    font-weight: 600;
                }

                .omega-modal-header omega-button {
                    flex-shrink: 2;
                }

                #omega-file-format-glossary {
                    z-index: -1;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%) scale(0);
                    transition: all 0.5s cubic-bezier(0.6, -0.28, 0.735, 0.045);
                    min-height: 400px;
                    width: 50%;
                    min-width: 525px;
                    max-width: 615px;
                    border-radius: 4px;
                }

                #omega-file-format-glossary.show {
                    z-index: 11;
                    transform: translate(-50%, -50%) scale(1);
                    transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
                }

                #omega-file-format-glossary ul {
                    list-style: none;
                    padding: 0 10px;
                }

                #omega-file-format-glossary li {
                    margin-top: 5px;
                }

                #omega-file-format-glossary .glossary-term-name {
                    font-weight: bold;
                }
                .exclude-inputs {
                    display: flex;
                }
                omega-radio-group {
                    margin: 15px 5px;
                    --omega-radio-group-flex-direction: row;
                    flex-shrink: 0;
                    --omega-text-secondary: #444444;
                }
                @media (max-width: 1200px) {
                    omega-radio-group {
                        --omega-radio-group-flex-direction: column;
                        margin-left: 24px;
                    }
                    .omega-file-format-options-row {
                        justify-content: flex-start;
                    }
                }
                @media (max-width: 900px) {
                    .exclude-inputs {
                        display: block;
                    }
                }
            `,
        ];
    }
}

customElements.define('omega-file-format-designer', OmegaFileFormatDesigner);
export default OmegaFileFormatDesigner;
